
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function categoryVehicleWidgetRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3557'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3759'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': 'form-field ' + this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '49'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select form-select',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', 'Select ', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7651'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7654'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ 'Select ' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'form-input cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'key': '2872'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', 'Select ', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_model-selector category' }, this.template === 'active' ? _createElement('div', {
        'className': 'active-container themevale_MultiCategory cmTemplate_active',
        'key': '43'
    }, _createElement('div', { 'className': 'themevale_multilevel-category-filter' }, _createElement('div', { 'className': 'model-selector-body form-wrapper' }, _createElement.apply(this, [
        'div',
        { 'className': 'selects-container form-wrapper cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'buttons-container' }, _createElement('div', {
        'className': 'cm_button button button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle
    }, '\n    GO\n  '), _createElement('div', {
        'className': 'cm_button button button--primary',
        'id': 'themevale_clear-select',
        'title': 'Reset',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle
    }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-refresh' }))))))) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'locked-container page-heading cmTemplate_locked',
        'key': '6541'
    }, _createElement('div', { 'className': 'model-selector-title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'model-selector-subtitle' }, this.fitmentSearchTitle)), _createElement('div', { 'className': 'model-selector-body' }, _createElement('div', { 'className': 'buttons-container' }, _createElement('div', {
        'className': 'cm_button button button--primary',
        'onClick': this.openDialog
    }, 'Change'), _createElement('div', {
        'className': 'button button--primary',
        'id': 'themevale_clear-select',
        'title': 'Reset',
        'onClick': this.discardVehicle
    }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-refresh' })))))) : null);
}
        export const componentNames = ["cm:filterInput"]