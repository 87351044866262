
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function FacetPanelContainerRT () {
    return _createElement('div', { 'className': 'cm_FacetPanel cm_facet-panel__full-width' }, [this.filterChips(function () {
            function repeatChips1(chips, chipsIndex) {
                return [chips(function () {
                        return this.template === 'chip' ? _createElement('li', { 'className': 'cmTemplate_chip' }, _createElement('a', { 'className': 'facetLabel' }, '\n  ', this.value, '\n  ', _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-close' })))) : this.template === 'rangeChip' ? _createElement('li', { 'className': 'cmTemplate_rangeChip' }, _createElement('a', { 'className': 'facetLabel' }, this.finite ? [
                            '$',
                            this.selectedRange[0],
                            ' to $',
                            this.selectedRange[1]
                        ] : null, this.toPosInf ? [
                            '$',
                            this.selectedRange[0],
                            ' and more'
                        ] : null, this.toNegInf ? [
                            '$',
                            this.selectedRange[1],
                            ' and less'
                        ] : null, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-close' })))) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'facetedSearch-refineFilters sidebarBlock cm_filterChips' }, _createElement('div', { 'className': 'sidebarBlock-heading facettitle' }, 'Refine by'), _createElement.apply(this, [
                'ul',
                { 'className': 'inlineList inlineList--labels facetbody cmRepeater_chips' },
                _map(this.chips, repeatChips1.bind(this))
            ]), _createElement('a', {
                'className': 'cm_btn',
                'data-cm-role': 'start-over'
            }, 'Clear all'));
        }, {
            widgetName: 'filter-chips',
            items: undefined
        })], [this.facets(function () {
            function repeatFacets1(facets, facetsIndex) {
                return [facets(function () {
                        function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                            return [ShowAlwaysValues(function () {
                                    return _createElement('li', { 'className': 'navList-item' + (this.treeLevel > 0 ? ` cm_padding-${ this.treeLevel }` : '') }, _createElement('a', { 'className': 'navList-action navList-action--checkbox' + (this.isSelected ? ' is-selected' : '') }, _createElement('span', { 'className': 'navList-action-value' }, this.value, ' (', this.hitCount, ')'), this.isSelected ? _createElement('span', {
                                        'className': 'navList-action-close',
                                        'aria-hidden': 'true',
                                        'key': '323'
                                    }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-close' }))) : null));
                                }, { count: undefined })];
                        }
                        function repeatValues2(Values, ValuesIndex) {
                            return [Values(function () {
                                    return _createElement('li', { 'className': 'navList-item' + (this.treeLevel > 0 ? ` cm_padding-${ this.treeLevel }` : '') }, _createElement('a', { 'className': 'navList-action navList-action--checkbox' + (this.isSelected ? ' is-selected' : '') }, _createElement('span', { 'className': 'navList-action-value' }, this.value, ' (', this.hitCount, ')'), this.isSelected ? _createElement('span', {
                                        'className': 'navList-action-close',
                                        'aria-hidden': 'true',
                                        'key': '323'
                                    }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-close' }))) : null));
                                }, { count: undefined })];
                        }
                        function repeatValues3(Values, ValuesIndex) {
                            return [Values(function () {
                                    return _createElement('li', { 'className': 'navList-item' }, _createElement('a', { 'className': 'navList-action navList-action--checkbox ' + (this.isSelected ? 'is-selected' : '') }, _createElement('span', { 'className': 'navList-action-value' }, !this.isCustomRange ? [
                                        this.value,
                                        '  (',
                                        this.hitCount,
                                        ')'
                                    ] : null, this.isCustomRange ? [
                                        this.finite ? [
                                            '$',
                                            this.range[0],
                                            ' - $',
                                            this.range[1]
                                        ] : null,
                                        this.toPosInf ? [
                                            '$',
                                            this.range[0],
                                            ' and more'
                                        ] : null,
                                        this.toNegInf ? [
                                            '$',
                                            this.range[1],
                                            ' and less'
                                        ] : null
                                    ] : null), this.isSelected ? _createElement('span', {
                                        'className': 'navList-action-close',
                                        'aria-hidden': 'true',
                                        'key': '677'
                                    }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-close' }))) : null));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'sidebarBlock' }, _createElement('div', {
                            'className': 'facettitle sidebarBlock-heading',
                            'data-cm-role': 'toggle-facet'
                        }, this.name, ' \n    ', this.isCollapsed ? [_createElement('svg', {
                                'className': 'cm_icon cm_icon-plus-minus',
                                'height': '20px',
                                'role': 'img',
                                'viewBox': '0 0 448 512',
                                'key': '1240'
                            }, _createElement('path', { 'd': 'M416,208H272V64c0-17.67-14.33-32-32-32h-32c-17.67,0-32,14.33-32,32v144H32c-17.67,0-32,14.33-32,32v32c0,17.67,14.33,32,32,32h144v144c0,17.67,14.33,32,32,32h32c17.67,0,32-14.33,32-32V304h144c17.67,0,32-14.33,32-32v-32c0-17.67-14.33-32-32-32z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                'className': 'cm_icon cm_icon-plus-minus',
                                'height': '20px',
                                'role': 'img',
                                'viewBox': '0 0 448 512',
                                'key': '5280'
                            }, _createElement('path', { 'd': 'M416,208H32c-17.67,0-32,14.33-32,32v32c0,17.67,14.33,32,32,32h384c17.67,0,32-14.33,32-32v-32c0-17.67-14.33-32-32-32z' }))] : null), _createElement('div', {
                            'className': 'facetbody',
                            'role': 'list'
                        }, this.template === 'simpleFacet' ? _createElement('div', {
                            'className': 'sidebarBlock-content cmTemplate_simpleFacet',
                            'key': '858'
                        }, this.showFilterInput ? _createElement('div', {
                            'className': 'filter-input',
                            'key': '965'
                        }, [this.filterInput(function () {
                                return _createElement('div', {
                                    'className': 'input form-input cm_filterInput',
                                    'placeholder': 'Enter'
                                });
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })], this.inputNotEmpty ? _createElement('span', {
                            'className': 'filter-input_clear-container',
                            'onClick': this.clearInput,
                            'key': '1295'
                        }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                'className': 'facetdiv',
                                'key': '14641'
                            }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                            'ul',
                            { 'className': 'navList cmRepeater_ShowAlwaysValues' },
                            _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                        ]), _createElement.apply(this, [
                            'ul',
                            { 'className': 'navList cmRepeater_Values' },
                            _map(this.Values, repeatValues2.bind(this))
                        ]), this.needShowMore ? _createElement('div', {
                            'className': 'facetdiv cm_show-all-container',
                            'key': '3865'
                        }, _createElement('a', {
                            'className': 'cm_show-all',
                            'data-cm-role': 'toggle-show-more'
                        }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'priceFacet' ? _createElement('div', {
                            'className': 'sidebarBlock-content cmTemplate_priceFacet',
                            'key': '4178'
                        }, _createElement('div', {}, _createElement.apply(this, [
                            'ul',
                            { 'className': 'navList cmRepeater_Values' },
                            _map(this.Values, repeatValues3.bind(this))
                        ]), [this.Inputs(function () {
                                return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                        return _createElement('div', {
                                            'className': 'form-input cm_inputMin',
                                            'placeholder': 'Min price'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                        return _createElement('div', {
                                            'className': 'form-input cm_inputMax',
                                            'placeholder': 'Max price'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], _createElement('button', {
                                    'type': 'button',
                                    'className': 'cm_btn button',
                                    'onClick': this.setCustomRange
                                }, '\n      GO\n    '));
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })])) : null));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'facetedSearch-navList blocker-container cm_facets' }, _createElement.apply(this, [
                'div',
                { 'className': 'accordion--navList cmRepeater_facets' },
                _map(this.facets, repeatFacets1.bind(this))
            ]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:filterChips","cm:filterInput","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets"]