//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-456:_8960,_6492,_1700,_5964,_8216,_9552,_964,_7180;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-456')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-456', "_8960,_6492,_1700,_5964,_8216,_9552,_964,_7180");
        }
      }catch (ex) {
        console.error(ex);
      }