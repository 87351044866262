import fpautopartsDefaults from '../fpautoparts/config.js';

const widgetsToRemove = [
  'HeaderVehicleWidget_miniStore',
  'HeaderVehicleWidget_miniStore2',
  'FacetPanelCategory',
];

export default {
  includes: ['fpautoparts'],
  ...fpautopartsDefaults,
  fitmentSearch: {
    ...fpautopartsDefaults.fitmentSearch,
    categorySelectionPages: [
      { pathname: '/vehicle/', field: 'category' },
      { pathname: '/parts/', field: 'category' },
    ],
    isVehicleVisibleInUrl: true,
  },
  product: {
    noImageSrc: '/product_images/uploaded_images/productdefault.jpg',
  },
  Widgets: [
    ...fpautopartsDefaults.Widgets.filter((w) => !widgetsToRemove.includes(w.name)),
    {
      name: 'FacetPanelCategory',
      type: 'FacetPanel',
      location: {
        firstChildOf:
          '.page-type-category #faceted-search-container > nav, .page-type-brand #faceted-search-container > nav',
      },
      template: 'FacetPanelContainer',
      ignoreFields: ['Submodel', 'category_full', 'Vehicle'],
    },
  ],
};
