
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function scopePre_order2() {
                    var pre_order = this.availability === 'preorder' && this.preorder_release_date ? true : false;
                    return _createElement('article', {
                        'className': 'card',
                        'data-product-id': this.id
                    }, _createElement('div', { 'className': 'card-figure' }, _createElement('div', { 'className': 'themevale_badges' }, this.out_of_stock ? _createElement('div', {
                        'className': 'soldOut-badge themevale_badge',
                        'key': '287'
                    }, _createElement('span', { 'className': 'text' }, 'sold out')) : null), _createElement('a', {
                        'href': this.custom_url,
                        'className': 'product_img_link'
                    }, _createElement('div', { 'className': 'card-img-container' }, _createElement('img', {
                        'className': 'card-image lazyautosizes lazyloaded',
                        'data-sizes': 'auto',
                        'src': this.imageOrDefault(this.image_thumbnail),
                        'data-src': this.image_thumbnail,
                        'onError': this.onImageError,
                        'alt': this.removeHTML(this.name),
                        'sizes': '50px'
                    }))), _createElement('div', { 'className': 'card-buttonGroup' }, _createElement('a', {
                        'className': 'card-button-quickview quickview',
                        'data-product-id': this.id
                    }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-eye' })), _createElement('span', { 'className': 'text' }, 'quick view')), _createElement('a', {
                        'href': '/wishlist.php?action=add&product_id=' + this.id,
                        'className': 'card-button-wishlist',
                        'title': 'Add to Wish List'
                    }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-heart' })))), _createElement('div', { 'className': 'card-figcaption' }, pre_order ? [_createElement('a', {
                            'href': this.custom_url,
                            'className': 'button card-figcaption-button',
                            'data-product-id': this.id,
                            'key': '12721'
                        }, '\n          Pre-Order Now\n        ')] : null, !pre_order && this.out_of_stock ? [_createElement('a', {
                            'href': this.custom_url,
                            'className': 'button card-figcaption-button',
                            'data-product-id': this.id,
                            'key': '14691'
                        }, '\n          View Product\n        ')] : null, !pre_order && !this.out_of_stock ? [
                        !this.has_options ? _createElement('a', {
                            'href': '/cart.php?action=add&product_id=' + this.id,
                            'className': 'button card-figcaption-button themevale_btnATC',
                            'data-product-id': this.id,
                            'key': '16951'
                        }, '\n          Add to Cart\n        ') : null,
                        this.has_options ? _createElement('a', {
                            'href': this.custom_url,
                            'className': 'button card-figcaption-button',
                            'data-product-id': this.id,
                            'key': '16953'
                        }, '\n          Choose Options\n        ') : null
                    ] : null)), _createElement('div', { 'className': 'card-body' }, _createElement('div', { 'className': 'card-detail' }, _createElement('p', mergeProps({
                        'className': 'card-brand',
                        'data-test-info-type': 'brandName'
                    }, { dangerouslySetInnerHTML: { __html: this.brand_name } })), _createElement('h4', { 'className': 'card-title' }, _createElement('a', Object.assign({}, { 'href': this.custom_url }, { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('p', {
                        'className': 'card-rating',
                        'data-test-info-type': 'productRating'
                    }), _createElement('div', { 'className': 'card-price-wrapper' }, _createElement('div', {
                        'className': 'card-price',
                        'data-test-info-type': 'price'
                    }, this.retail_price ? _createElement('div', {
                        'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                        'key': '2671'
                    }, '\n            MSRP: ', _createElement('span', { 'className': 'price price--rrp' }, this.formatPrice(this.retail_price))) : null, _createElement('div', { 'className': 'price-section price-section--withoutTax non-sale-price--withoutTax price-none' }, _createElement('span', { 'className': 'price price--non-sale' })), _createElement('div', { 'className': 'price-section price-section--withoutTax' }, _createElement('span', { 'className': 'price price--withoutTax' }, this.formatPrice(this.price))))), _createElement('div', { 'className': 'card-desciption' }, _createElement('p', mergeProps({ 'className': 'card-text' }, { dangerouslySetInnerHTML: { __html: this.name } })))), _createElement('div', { 'className': 'card-actions' }, _createElement('div', { 'className': 'card-figcaption' }, pre_order ? [_createElement('a', {
                            'href': this.custom_url,
                            'className': 'button card-figcaption-button',
                            'data-product-id': this.id,
                            'key': '34751'
                        }, '\n            Pre-Order Now\n          ')] : null, !pre_order && this.out_of_stock ? [_createElement('a', {
                            'href': this.custom_url,
                            'className': 'button card-figcaption-button',
                            'data-product-id': this.id,
                            'key': '36821'
                        }, '\n            View Product\n          ')] : null, !pre_order && !this.out_of_stock ? [
                        !this.has_options ? _createElement('a', {
                            'href': '/cart.php?action=add&product_id=' + this.id,
                            'className': 'button card-figcaption-button themevale_btnATC',
                            'data-product-id': this.id,
                            'key': '39181'
                        }, '\n            Add to Cart\n          ') : null,
                        this.has_options ? _createElement('a', {
                            'href': this.custom_url,
                            'className': 'button card-figcaption-button',
                            'data-product-id': this.id,
                            'key': '39183'
                        }, '\n            Choose Options\n          ') : null
                    ] : null), _createElement('div', { 'className': 'form-wishlist' }, _createElement('a', {
                        'href': '#',
                        'aria-expanded': 'false',
                        'className': 'button button--dropdown dropdown-menu-button',
                        'data-dropdown': 'wishlist-dropdown_' + this.id
                    }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-heart' })), _createElement('span', {}, 'Add to Wish List'), _createElement('svg', { 'className': 'icon icon-down' }, _createElement('use', { 'xlinkHref': '#icon-keyboard-arrow-down' }))), _createElement('ul', {
                        'aria-hidden': 'true',
                        'className': 'dropdown-menu',
                        'data-dropdown-content': true,
                        'id': 'wishlist-dropdown_' + this.id,
                        'tabIndex': '-1'
                    }, _createElement('li', {}, _createElement('a', { 'href': '/wishlist.php?action=add&product_id=' + this.id }, 'Add to My Wish List')), _createElement('li', {}, _createElement('a', { 'href': '/wishlist.php?action=addwishlist&product_id=' + this.id }, 'Create New Wish List')))), _createElement('label', {
                        'className': 'card-compare',
                        'htmlFor': 'compare2-' + this.id
                    }, _createElement('input', {
                        'type': 'checkbox',
                        'name': 'products[]',
                        'value': this.id,
                        'id': 'compare2-' + this.id,
                        'data-compare-id': this.id
                    }), _createElement('span', {}, 'Compare')))));
                }
                return _createElement('div', { 'className': 'productCarousel-slide' }, scopePre_order2.apply(this, []));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-items-container' }, _createElement('div', { 'className': 'toggle-title' }, _createElement('h4', { 'className': 'page-heading' }, 'You may also like...')), _createElement('div', {
        'className': 'toggle-content is-open',
        'id': 'productRelated-content',
        'aria-hidden': 'false'
    }, _createElement.apply(this, [
        'div',
        {
            'className': 'productCarousel cmRepeater_items',
            'data-slick': window.Convermax.product.dataSlick
        },
        _map(this.items, repeatItems1.bind(this))
    ])));
}
        export const componentNames = []